<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('sfrz.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <!-- <van-row class="identity">
          <van-cell
            :title="$t('sfrz.sfrz')"
            center
            is-link
            to="/person/identity"
          >
            <template #default>
              <span v-if="oauth == 0">{{ $t('sfrz.auth0') }}</span>
              <span v-if="oauth == 1">{{ $t('sfrz.auth1') }}</span>
              <span v-if="oauth >= 2">{{ $t('sfrz.auth2') }}</span>
            </template>
          </van-cell>
          <van-row class="identity-notice">
            <span>{{ $t('sfrz.text1') }}</span>
            <span>{{ $t('sfrz.text2') }}</span>
          </van-row>
        </van-row> -->
        <van-row class="identity">
          <van-cell :title="$t('sfrz.gjrz')" center @click.prevent="toadvanced">
            <template #default>
              <span v-if="oauth < 3">{{ $t('sfrz.auth0') }}</span>
              <span v-if="oauth == 3">{{ $t('sfrz.auth3') }}</span>
              <span v-if="oauth == 4">{{ $t('sfrz.auth4') }}</span>
            </template>
          </van-cell>
          <van-row class="identity-notice">
            <!-- <span>{{ $t('sfrz.text3') }}</span> -->
            <span>{{ $t('sfrz.text6') }}</span>
            <span>
              {{ $t('sfrz.text5') }}
            </span>
          </van-row>
        </van-row>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      oauth: ''
    }
  },
  created() {
    this.getoauth()
  },
  methods: {
    async getoauth() {
      const { data } = await this.$http.get('/home/home/getoauth')
      if (data) {
        if (data.code === 200) {
          this.oauth = data.data
        }
      }
    },
    // 返回
    toadvanced() {
      // if (parseInt(this.oauth) < 2) {
      //   this.$toast.fail(this.$t('sfrz.toast'))
      //   return false
      // }
      this.$router.push('/person/advanced')
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f8f8f8;
  height: 100vh;
  .spacing {
    margin-top: 0.8rem;
    width: 100%;
    height: 100%;
    .identity {
      width: 90%;
      height: 13.33333rem;
      background-color: #fff;
      margin-left: 5%;
      margin-top: 4%;
      border-radius: 0.53333rem;
      .van-cell {
        font-size: 1rem;
        background-color: #fff !important;
        color: #000 !important;
        border-radius: 0.53333rem;
        .van-cell__title {
          text-align: left;
        }
        .van-cell:after {
          border-bottom: 0.02667rem solid #e5e5e5;
        }
      }
      .identity-notice span {
        color: #b2b2b2;
        display: block;
        font-size: 0.98667rem;
        text-align: left;
        margin: 1.33333rem 0 0 1.33333rem;
      }
    }
  }
}
</style>
